import React, { useState } from "react";
import cn from "classnames";
import styles from "./Panel.module.sass";
import Icon from "../../Icon";
import { usePermissions } from "../../../contexts/PermissionsContext";
import zIndex from "@mui/material/styles/zIndex";

const Panel = ({
  galleryPanel,
  currentCreation,
  onGenerateTripleOffer,
  onGeneratePersonalOffer6,
  onGenerateDecoy,
  show,
}) => {
  const { permissions } = usePermissions();
  const [showGenerateDropdown, setShowGenerateDropdown] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);

  console.log("currentCreation", currentCreation);
  console.log("SHOW", show);

  const generateOptions = [
    {
      value: "triple",
      label: "Triple Rolling Offer",
      action: onGenerateTripleOffer,
      permission: "triple rolling offer",
    },
    {
      value: "personal6",
      label: "Personal Offer 6",
      action: onGeneratePersonalOffer6,
      permission: "personal offer 6",
    },
    {
      value: "decoy",
      label: "Decoy",
      action: onGenerateDecoy,
      permission: "decoy",
    },
  ];

  const validGenerateOptions = generateOptions.filter(
    (option) => option.action && permissions && permissions[option.permission]
  );

  const handleGenerateOption = async (option) => {
    setIsGenerating(true);
    await option.action(currentCreation);
    setIsGenerating(false);
    setShowGenerateDropdown(false); // Close the dropdown after selecting an option
  };

  return (
    <div className={cn(styles.panel, { [styles.panelGallery]: galleryPanel })}>
      <div className={styles.actions} style={{ display: "flex" }}>
        <div>
          {show && (
            <>
              {validGenerateOptions.length > 0 && (
                <div className={styles.generateOfferContainer}>
                  {
                    <div
                      className={styles.generateContainer}
                      style={{ marginTop: "20px" }}
                    >
                      <button
                        className={cn("button-small", styles.button)}
                        onClick={() =>
                          setShowGenerateDropdown(!showGenerateDropdown)
                        }
                        style={{
                          pointerEvents: "auto",
                          backgroundColor: "purple",
                          color: "white",
                        }}
                      >
                        {!isGenerating && (
                          <div>
                            <Icon name="promotion" size="24" />
                            <span
                              className={styles.generateOffer}
                              style={{ pointerEvents: "auto", width: "100%" }}
                            >
                              Generate Offer
                            </span>
                            <Icon
                              name="arrow-down"
                              size="16"
                              className={styles.arrowIcon}
                            />
                          </div>
                        )}
                        {isGenerating && (
                          <div
                            style={{
                              display: "flex",
                              gap: "20px",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <span
                              className={styles.download}
                              style={{ pointerEvents: "auto", width: "100%" }}
                            >
                              Please Wait...
                            </span>
                          </div>
                        )}
                      </button>

                      {showGenerateDropdown && (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "20px",
                            gap: "10px",
                            flexShrink: 0,
                            flexDirection: "column",
                          }}
                        >
                          {validGenerateOptions.map((option) => (
                            <button
                              style={{
                                width: "200px",
                                backgroundColor: "purple",
                                color: "white",
                              }} // Purple background and white text
                              key={option.value}
                              className={cn("button-small", styles.button)}
                              onClick={() => handleGenerateOption(option)}
                              disabled={isGenerating}
                            >
                              {option.label}
                            </button>
                          ))}
                        </div>
                      )}
                    </div>
                  }
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Panel;
