import React, { useState, useEffect } from 'react';
import styles from './InputMatrix.module.sass';
import Icon from '../Icon';
import CustomTooltip from "../CustomTooltip/index";
import TooltipData from "../CustomTooltip/TooltipsData";
import ReferenceSelector from "../ReferenceSelector/index";
import ImageUpload from "../PromptBox/ImageUpload";
import { he } from 'date-fns/locale';
import { clear } from '@testing-library/user-event/dist/cjs/utility/clear.js';

export const clearInputMatrixLocalStorage = () => {
  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);
    if (key.startsWith('sketchImage-') || key.startsWith('referenceType-')) {
      localStorage.removeItem(key);
      i--; // Adjust index since localStorage length changes
    }
  }
};


const InputMatrix = ({ isDisabledList, textsList, setTextsList, isAllowedRegenerateList, regenerateCreation, artifact, organizationData, ReferencesOptions }) => {
  const [keysList, setKeysList] = useState(Object.keys(textsList));
  // console.log('keysList:', keysList);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [editingPrompt, setEditingPrompt] = useState({ key: null, value: "" });
  const [selectedReferenceOption, setSelectedReferenceOption] = useState(""); // Add state for selected reference
  const [selectedImage, setSelectedImage] = useState(null); // Add state for selected image
  const [imageUploadProps, setImageUploadProps] = useState({});
  const [referencesMatrix, setReferencesMatrix] = useState({});

  useEffect(() => {
    setKeysList(Object.keys(textsList)); // Update keysList whenever textsList changes
  }, [textsList]);

  const handleRegenerateClick = (index) => {
    console.log("index", index);
    const x = Math.floor(index / 3); // Calculate row index (x)
    const y = index % 3; // Calculate column index (y)
    console.log("x, y", x, y);
    regenerateCreation(x, y);
  };

  const handleEditCardPrompt = (key) => {
    const currentData = textsList[key] || "";

    let prompt, referenceType, sketchImage;

    if (typeof currentData === "string") {
      prompt = currentData;
      referenceType = "No Reference";
      sketchImage = null;
    } else {
      ({ prompt, referenceType, sketchImage } = currentData);
      prompt = prompt || "";
      referenceType = referenceType || "No Reference";
      sketchImage = sketchImage || null;
    }
  
    // Load saved image from localStorage
    const savedImage = localStorage.getItem(`sketchImage-${key}`);
    if (savedImage) {
      sketchImage = savedImage;
    }
  
    // Load saved referenceType from localStorage
    const savedReferenceType = localStorage.getItem(`referenceType-${key}`);
    if (savedReferenceType) {
      referenceType = savedReferenceType;
    }
    
    setEditingPrompt({
      key,
      value: prompt,
      referenceType,
      sketchImage,
    });
    // console.table({
    //   key,
    //   value: prompt,
    //   referenceType,
    //   sketchImage,
    // });
  
    setIsPopupOpen(true);
    // setSelectedReferenceOption(referenceType);
  };

  const handleReferenceOptionChange = (option) => {
    setSelectedReferenceOption(option);
    setEditingPrompt((prev) => ({ ...prev, referenceType: option }))
  };

  // const handleSavePrompt = () => {
  //   const updatedTextsList = {
  //     ...textsList, // Spread the current `textsList`
  //     [editingPrompt.key]: {
  //       prompt: editingPrompt.value, // Update the prompt value
  //       reference_type: editingPrompt.referenceType, // Update the reference type
  //       sketch_image: editingPrompt.sketchImage, // Update the sketch image
  //     },
  //   };
  
  //   setTextsList(updatedTextsList); // Save to parent component
  //   localStorage.setItem(`referenceType-${editingPrompt.key}`, editingPrompt.referenceType);
  //   setIsPopupOpen(false); // Close the popup
  // };

  const handleSavePrompt = () => {
    const updatedTextsList = {
      ...textsList,
      [editingPrompt.key]: {
        prompt: editingPrompt.value,
        reference_type: editingPrompt.referenceType,
        sketch_image: editingPrompt.sketchImage,
      },
    };

    setTextsList(updatedTextsList);
    localStorage.setItem(`referenceType-${editingPrompt.key}`, editingPrompt.referenceType);
    setIsPopupOpen(false);
  };

  const updateSelectedImage = (image) => {
    if (image) {
      const reader = new FileReader();
      reader.onloadend = () => {
        localStorage.setItem(`sketchImage-${editingPrompt.key}`, reader.result); // Save Base64 image
        setEditingPrompt((prev) => ({ ...prev, sketchImage: reader.result })); // Update state
      };
      reader.readAsDataURL(image); // Convert to Base64
    } else {
    }
  };

  const clearImage = () => {
    localStorage.removeItem(`sketchImage-${editingPrompt.key}`); // Remove from storage
    setEditingPrompt((prev) => ({ ...prev, sketchImage: null })); // Clear state
    handleReferenceOptionChange(ReferencesOptions[0]); // Reset dropdown
  };

  const updateSelectedReference = (referenceType) => {
    setEditingPrompt((prev) => ({ ...prev, referenceType }));
    setSelectedReferenceOption(referenceType);
  };

  const handleInputChange = (index, value) => {
    const key = keysList[index];
    const newTextsList = { ...textsList, [key]: value };
    setTextsList(newTextsList);
  };

  const renderMatrix = () => {
    return keysList.map((key, index) => (
      <div className={styles.inputButtonContainer} key={index}>
        <input
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              // onRegenerateClicked(index);
              regenerateCreation(index);
            }
          }}
          className={styles.smallInput}
          type="text"
          placeholder={`Card ${index + 1} Key`}
          value={key}
          onChange={(e) => handleInputChange(index, e.target.value)}
          disabled={isDisabledList[index]}
        />
        <CustomTooltip title={TooltipData["EditPrompt"]} placement="top">
          <button onClick={() => handleEditCardPrompt(key)} className={styles.button}>
            <Icon name="edit" size="18" />
          </button>
        </CustomTooltip>
        {isAllowedRegenerateList[index] && (
          <CustomTooltip title={TooltipData["Regenerate"]} placement="top">
            {/* <button onClick={() => onRegenerateClicked(index)} className={styles.button}> */}
            <button onClick={() => handleRegenerateClick(index)} className={styles.button}>
              <Icon name="repeat" />
            </button>
          </CustomTooltip>
        )}
      </div>
    ));
  };

  return (
    <div className={styles.matrix}>
      {renderMatrix()}
      {isPopupOpen && (
  <>
    <div className={styles.overlay} onClick={() => setIsPopupOpen(false)} />
    <div className={styles.popup}>
      <div className={styles.popupContent}>
        <h3>Edit Prompt: {editingPrompt.key}</h3>
        <textarea
          style={{ width: "100%", height: "100px", fontSize: "19px" }}
          value={editingPrompt.value}
          onChange={(e) => setEditingPrompt({ ...editingPrompt, value: e.target.value })}
        />
        <div>
          <button
            style={{
              backgroundColor: "#823bf5",
              width: "40%",
              height: '30px',
              borderRadius: "5px",
              marginRight: '10px',
              fontSize: '17px',
              color: 'white',
              fontWeight: 'bold',
            }}
            onClick={handleSavePrompt}
          >
            Save
          </button>
          <button
            style={{
              backgroundColor: "#823bf5",
              width: "40%",
              height: '30px',
              borderRadius: "5px",
              fontSize: '17px',
              color: 'white',
              fontWeight: 'bold',
            }}
            onClick={() => setIsPopupOpen(false)}
          >
            Close
          </button>
          <ReferenceSelector
            organizationData={organizationData}
            artifact={artifact}
            // selectedReferenceOption={editingPrompt.referenceType}
            selectedReferenceOption={selectedReferenceOption}
            // onReferenceChange={(option) => {
            //   handleReferenceOptionChange(option);
            //   setEditingPrompt((prev) => ({ ...prev, referenceType: option })); // Update state
            // }}
            onReferenceChange={handleReferenceOptionChange}
            onImageChange={(image) => {
              updateSelectedImage(image); // Save image
            }}
            setImageUploadProps={setImageUploadProps}
            // passReferencesOptions={(options) => console.log("References options:", options)}
            passReferencesOptions={() => null}
            style={{
              marginTop: "6px",
              transform: "translateX(64%)",
              marginBottom: "1px",
              width: "170px",
            }}
          />

        </div>
          <ImageUpload
              imagePreview={
                editingPrompt.sketchImage
                  ? editingPrompt.sketchImage // Base64 image from localStorage
                  : null
              }
              handleFileChange={imageUploadProps.handleFileChange}
              clearImage={clearImage} // Clear image
              fileInputRef={imageUploadProps.fileInputRef}
          />
        </div>
      </div>
    </>
  )}
  </div>
  );
};

export default InputMatrix;
