import React, { useEffect, useState } from "react";
import styles from "./Layout.module.sass";

const Layout = ({ layouts, onLayoutSelect}) => {
  const [scrollPositions, setScrollPositions] = useState({});
  const [selectedLayout, setSelectedLayout] = useState(null)

  const handleWheel = (event, layoutId) => {
    event.preventDefault();
    const newScrollPosition =
      (scrollPositions[layoutId] || 0) + event.deltaY;
    setScrollPositions((prev) => ({
      ...prev,
      [layoutId]: newScrollPosition,
    }));
  };

  const handleSelectLayout = (layoutId, must_have, back_image_url) => {
    if (selectedLayout === layoutId && !must_have) {
      // Deselect if the layout is already selected and `must_have` is false
      setSelectedLayout(null);
    } else if (selectedLayout !== layoutId) {
      // Select the layout if it is not already selected
      setSelectedLayout(layoutId);
    }
    if(onLayoutSelect){
      onLayoutSelect(layoutId, back_image_url);
    }
  };

  return (
    <>
      {layouts.map((layout) => (
      <>
        <h3 style={{ marginTop: "10px", marginBottom: "-38px", transform:"translateX(-225%)" , zIndex: 100 }}>
          {layout.display_name}
        </h3>
        <div
          key={layout.layout_id}
          className={styles.input}
          style={{
            display: "flex",
            overflowX: "scroll",
            scrollBehavior: "smooth",
            marginBottom: "20px",
          }}
          onWheel={(e) => handleWheel(e, layout.layout_id)}
        >
        {/* <div>
            <h4 style={{overflow: true ,width: "100%", backgroundColor:"red"}}>{layout.display_name}</h4>
        </div> */}
          {layout.options.map((option, index) => (
            <div
              key={`${layout.layout_id}-${index}`}
              className={styles.item}
              onClick={() =>
                handleSelectLayout(`${layout.layout_id}-${index}`, layout.must_have, option.back_image_url)
              } // Handle click to select layout option
              style={{
                margin: "7px",
                marginTop: "38px",
                cursor: "pointer",
                border:
                  selectedLayout === `${layout.layout_id}-${index}`
                    ? "2px solid #7f49f4"
                    : "none", // Highlight selected layout option
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  transform: "translateX(-32%)",
                  fontSize: "10px",
                  marginTop: "10px",
                }}
              >
                layout {index + 1}
              </div>
              <div
                style={{
                  backgroundImage: `url(${option.front_image_url})`, // Use the front_image_url
                  backgroundSize: "contain",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  borderRadius: "10px",
                  height: "100px",
                  flex: "0 0 auto",
                  margin: "10px",
                  marginTop: "0px",
                }}
              ></div>
            </div>
          ))}
        </div>
        </>
      ))}
    </>
  );
};

export default Layout;
