import { downloadImage, updateDownloaded } from "../utils";
import ApiService from "./ApiService";
import ZipCreator from "./ZipCreatorService";

const offersArtifactsIds = ["7f9ad9b4-85a9-47d0-ad6f-21533721e85e","4baa24aa-a711-4f3d-907b-b88fe27d0d29" ]
class DownloadService {
  static async DownloadCreation(creation) {
    console.log(creation);
    console.log("HERE")
    // Filter out non-PNG files
    let pngFiles = [];

  // Normalize creation.result_image_url to an array
  if (typeof creation.result_image_url === "string") {
    // Single string case
    pngFiles = creation.result_image_url.toLowerCase().endsWith(".png") 
      ? [creation.result_image_url] 
      : [];
  } else if (Array.isArray(creation.result_image_url)) {
    // Array case
    pngFiles = creation.result_image_url.flatMap((item) => 
      Array.isArray(item)
        ? item.filter((url) => url.toLowerCase().endsWith(".png"))
        : (typeof item === "string" && item.toLowerCase().endsWith(".png")) 
          ? [item] 
          : []
    );
  }

  if (pngFiles.length > 1 && offersArtifactsIds.includes(creation.creation_id)) {
    // Create a new object with filtered PNG URLs
    await DownloadService.DownloadComplexCreation(creation);
  } else {
      const currentImageUrl = pngFiles[0];
      await updateDownloaded(creation.creation_id, creation.creation_sub_id);
      downloadImage(currentImageUrl, "image.png");
    }
  }

  static async DownloadComplexCreation(creation) {
    const files = [
      {
        name: `${creation.creation_id}_${creation.creation_sub_id}_active.png`,
        url: creation.components_details.active_passive.result_image_url[0],
      },
      {
        name: `${creation.creation_id}_${creation.creation_sub_id}_passive.png`,
        url: creation.components_details.active_passive.result_image_url[1],
      },
      {
        name: `${creation.creation_id}_${creation.creation_sub_id}_title.png`,
        url: creation.components_details.title.result_image_url[0],
      },
      {
        name: `${creation.creation_id}_${creation.creation_sub_id}_title1.png`,
        url: creation.components_details.title.result_image_url[1],
      },
      {
        name: `${creation.creation_id}_${creation.creation_sub_id}_title2.png`,
        url: creation.components_details.title.result_image_url[2],
      },
    ];
    console.log(files);

    // Creating ZIP file using ZipCreator
    await ZipCreator.createZipFile(
      files,
      `${creation.creation_id}_${creation.creation_sub_id}`
    );

    // API call to toggle like status
    try {
      await ApiService.post(
        `/download_creation/${creation.creation_id}/${creation.creation_sub_id}`
      );
    } catch (error) {
      console.error("Failed to toggle like status:", error);
    }
  }

  static async DownloadCreationX2(creation) {
    const currentImageUrl = creation.result_image_url[0];
    await updateDownloaded(creation.creation_id, creation.creation_sub_id);

    try {
      // Using ApiService to get the upscaled image
      const result = await DownloadService.UpscaleImage(currentImageUrl);
      downloadImage(result.result[0], "image2.pngx2");
    } catch (error) {
      console.error("Failed to upscale image:", error);
    }
  }

  static async UpscaleImage(image_url) {
    const data_input = { image_url };
    try {
      // Using ApiService for POST request
      return await ApiService.post(`/get_upscaled_image`, data_input);
    } catch (error) {
      console.error("Error in UpscaleImage: ", error);
      throw error;
    }
  }
}

export default DownloadService;
