import React, { useState, useRef, useEffect } from "react";
import Dropdown from "../Dropdown";
import ImageUpload from "../PromptBox/ImageUpload";
import { toast } from "react-hot-toast";
import styles from "../PromptBox/PromptBox.module.sass";
import { he } from "date-fns/locale";
import { clear } from "@testing-library/user-event/dist/cjs/utility/index.js";

const MAX_IMAGE_DIMENSION = 2048;

const ReferenceSelector = ({ 
    organizationData,
    selectedReferenceOption,
    onReferenceChange,
    onImageChange,
    artifact,
    setImageUploadProps,
    passReferencesOptions,
    style,
    isDisabled = false,
}) => {
    console.log("artifact.type", artifact?.type);
    console.log("isDisabled", isDisabled);
    const fileInputRef = useRef(null);
    const [imagePreview, setImagePreview] = useState(null);
    
    const [ReferencesOptions, setReferencesOptions] = useState([]);

    useEffect(() => {
        if (organizationData && organizationData?.references) {
            const options = [
                ...new Set(
                    organizationData.references
                        .sort((a, b) => a.index - b.index)
                        .map((r) => r.reference_type)
                ),
            ];
            setReferencesOptions(options);
            passReferencesOptions(options);
        }
    }, [organizationData]);

    useEffect(() => {
        if (!ReferencesOptions.includes(selectedReferenceOption)) {
            handleReferenceOptionChange(ReferencesOptions[0]);
        }
    }, [ReferencesOptions]);

    useEffect(() => {
        if (!imagePreview){
            handleReferenceOptionChange(ReferencesOptions[0])
            console.log("because no imagepreview - selectedReferenceOption", selectedReferenceOption);
        }
    }, [imagePreview])

    useEffect(() => {
        setImageUploadProps({
            handleFileChange, 
            clearImage, 
            fileInputRef, 
            imagePreview
        });
    }, [imagePreview]);

    const handleFileChange = (e) => {
        console.log("function: handleFileChange", "FILE CHANGE:", e.target.files);
        const file = e.target.files[0];
        if (file) {
        validateAndSetImage(file);
        } else {
            onReferenceChange(ReferencesOptions[0]);
        }
    };

    const validateAndSetImage = (file) => {
        console.log("function: validateAndSetImage", "FILE:", file);
        const fileType = file.type;
        const validTypes = ["image/png", "image/jpeg", "image/jpg"];

        if (!validTypes.includes(fileType)) {
        toast.error(
            "Invalid file format. Please upload a PNG, JPG, or JPEG image."
        );
            onImageChange(null);
            setImagePreview(null);
            handleReferenceOptionChange(ReferencesOptions[0]);
        return;
        }

        const img = new window.Image();
        img.onload = () => {
        if (img.width > MAX_IMAGE_DIMENSION || img.height > MAX_IMAGE_DIMENSION) {
            toast.error(
            "Image dimensions exceed 2048px in one or both directions. Please upload a smaller image."
            );
            onImageChange(null);
            setImagePreview(null);
            handleReferenceOptionChange(ReferencesOptions[0]);
        } else {
            console.log("SetSelectedImage: ", file);
            onImageChange(file);
            setImagePreview(URL.createObjectURL(file));
            }
        };
        img.src = URL.createObjectURL(file);
    };

    const clearImage = () => {
        console.log("function: clearImage");
        onImageChange(null);
        setImagePreview(null);
        handleReferenceOptionChange(ReferencesOptions[0]);
    };

    useEffect(() => {
        if (selectedReferenceOption === ReferencesOptions[0]) {
            setImagePreview(null);
            onImageChange(null);
        }
    }, [selectedReferenceOption]);

    const filteredReferencesOptions =
    artifact?.type !== "character"
    ? ReferencesOptions.filter((option) => option !== "Pose Reference")
    : ReferencesOptions;

    const handleReferenceOptionChange = (option) => {
        if (onReferenceChange) onReferenceChange(option); // Update selectedReferenceOption in PromptBox
        if (option && option !== ReferencesOptions[0]) {
            fileInputRef.current?.click();
        }
        console.log("selectedReferenceOption", selectedReferenceOption);
    };

    useEffect(() => {
        if (selectedReferenceOption === ReferencesOptions[0]) {
            setImagePreview(null);
            onImageChange(null);
        }
    }, [selectedReferenceOption]);
    // console.log("selectedReferenceOption", selectedReferenceOption);

    return (
        <div>
        <Dropdown
        id="ReferencesOptions"
        value={selectedReferenceOption} // Ensure the selectedReferenceOption prop is passed here
        setValue={handleReferenceOptionChange}
        options={filteredReferencesOptions}
        style={{ ...style }}
        showToolTip={true}
        showOptionTooltips={true}
        // disabledOptions={
        //     artifact?.use_flux
        //     ? filteredReferencesOptions.filter(
        //         (option) =>
        //             ![
        //             "Concept Sketch",
        //             "Detailed Sketch",
        //             "Image Variations",
        //             "No Reference",
        //             ].includes(option)
        //         )
        //     : []
        // }
        isDisabled={isDisabled}
    />
        </div>
    );
};

    export default ReferenceSelector;
